<template>
  <div class="container">
    <el-page-header @back="goBack">
    </el-page-header>
    <div class="container-form">
      <el-form class="form" ref="form" :model="form" label-width="80px" label-position="top" :rules="rules">
        <el-form-item label="姓名" prop="name" required>
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone" required>
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email" required>
          <el-input v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item label="举报类型" prop="reportType" required>
          <el-select v-model="form.reportType" placeholder="请选择">
            <el-option label="传销" value="1"></el-option>
            <el-option label="博彩" value="2"></el-option>
            <el-option label="诈骗" value="3"></el-option>
            <el-option label="盗币" value="4"></el-option>
            <el-option label="NFT盗窃" value="5"></el-option>
            <el-option label="非法吸收公众存款" value="6"></el-option>
            <el-option label="交易平台跑路" value="7"></el-option>
            <el-option label="项目方跑路" value="8"></el-option>
            <el-option label="其他" value="9"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="举报名称" prop="reportName" required>
          <el-input v-model="form.reportName"></el-input>
        </el-form-item>
        <el-form-item label="举报金额" prop="reportAmount" required>
          <el-input v-model="form.reportAmount"></el-input>
        </el-form-item>
        <el-form-item label="举报信息" prop="reportMessage" required>
          <el-input v-model="form.reportMessage"></el-input>
        </el-form-item>
        <el-form-item label="是否报警" prop="isPolice" required>
          <el-radio-group v-model="form.isPolice">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>

    </div>

  </div>
</template>

<script>
import { repSub } from "@/api/case";
export default {
  name: "case",
  data() {
    return {
      form: {
        name: '',
        phone: '',
        email: '',
        reportType: null,
        reportName: '',
        reportAmount: '',
        reportMessage: '',
        isPolice: "0"
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入电话', trigger: 'blur' },
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
        ],
        reportType: [
          { required: true, message: '请选择举报类型', trigger: 'blur' },
        ],
        reportName: [
          { required: true, message: '请输入举报名称', trigger: 'blur' },
        ],
        reportAmount: [
          { required: true, message: '请输入举报金额', trigger: 'blur' },
        ],
        reportMessage: [
          { required: true, message: '请输入举报信息', trigger: 'blur' },
        ],
        isPolice: [
          { required: true, message: '请选择是否报警', trigger: 'blur' },
        ],
      }
    }
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          repSub(this.form).then(res => {
            this.$message.success(res.data)
            this.$refs.form.resetFields()
            this.$router.back()
          })
        }
      });
    },
    goBack(){
      this.$router.back()
    }
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

  &-form {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .form {
      width: 528px;
    }

  }
  /deep/.el-select{
    width: 100%;
  }
  /deep/.el-page-header{
    width: 528px
  }

}
</style>
